import { useRef, useEffect, useState, useContext } from "react";

import classes from "./LoginForm.module.scss";
import usernameIcon from "../assets/fa-user.svg";
import passwordIcon from "../assets/carbon_password.svg";
import ValidUserContext from "../authCheck";
import transparent_logo from "../assets/DarkPriorityPowerLogo.png"


let isInitial = true;

function LoginForm() {
  const validUserContext = useContext(ValidUserContext);
  const [rememberMe, setRememberMe] = useState(JSON.parse(localStorage.getItem("remember-me")) ? true : false);
  const [rememberMeValue, setRememberMeValue] = useState(localStorage.getItem("remember-me") ?? "");
  const [disclaimerAcknowledged, setDisclaimerAcknowledged] = useState(localStorage.getItem("disclaimer-ack") ?? false);


  const emailInputRef = useRef();
  const passwordInputRef = useRef();


  useEffect(() => {
    if (isInitial) {
      validUserContext.localAuthCheck(true);
      isInitial = false;
    }
  }, [validUserContext]);

  const submitHandler = (event) => {
    event.preventDefault();

    validUserContext.apiAuthCheck(
      emailInputRef.current.value,
      passwordInputRef.current.value,
      false
    );
  };
  
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    if(event.target.checked) {
      localStorage.setItem("remember-me", true);
    } else {
      localStorage.setItem("remember-me", false);

    }
  };

  const handleForgotClick = () => {
    validUserContext.forgotPassword()
  }

  const handleCheckboxChange = () => {
    localStorage.setItem("disclaimer-ack", true)
    setDisclaimerAcknowledged(true)
  }

  return (
    <div className={classes.logincontainer}>
      <form onSubmit={submitHandler} className={classes.form}>
        <div className={classes.loginInstructions}>
          Please enter your email address and password.
        </div>
        <div>
          <input
            className={classes.input}
            type="email"
            id="user-name"
            name="user-name"
            autoComplete="on"
            placeholder="E-mail"
            ref={emailInputRef}
            // value={rememberMeValue} 
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>

        <div>
          <input
            className={classes.input}
            type="password"
            id="user-password"
            name="user-password"
            autoComplete="off"
            placeholder="Password"
            ref={passwordInputRef}
            required={!validUserContext.isLoggedIn}
          ></input>
        </div>
        {/* <div className={classes.rememberMeContainer}>
          <input
            type="checkbox"
            id="remember-me"
            name="remember-me"
            checked={rememberMe}
            onChange={handleRememberMeChange}
          />
          <label htmlFor="remember-me" className={classes.rememberMeLabel}>
            Remember Me
          </label>
        </div> */}
        <div className={classes.forgot} onClick={() => handleForgotClick()}>Forgot Password?</div>
        <button
          className={`${disclaimerAcknowledged ? classes.loginBtn : classes.loginBtnDisabled}`}
          disabled={validUserContext.isLoggedIn || !disclaimerAcknowledged}
        >
          {disclaimerAcknowledged ? (validUserContext.isLoggedIn ? "Already logged in" : "Login"): "Please Acknowledge Disclaimer"}
        </button>
      </form>
      <div className={classes.rememberMe}>
          <div className={`${classes.disclaimerCheckboxContainer}` } >
            <input className={`${classes.disclaimerCheckbox}` } type="checkbox" checked={rememberMe} id="subCheckbox" name="subCheckbox" onChange={handleRememberMeChange}></input>
            <label className={`${classes.disclaimerCheckboxContainerText}` } for="exampleCheckbox">Remember Me</label>
          </div>
      </div> 
      <div>
        <a href="/"><img className={classes.loginBottomLogo} src={transparent_logo}></img></a>
      </div>
      {disclaimerAcknowledged? 
      (
        <></>
      )
        :
      (
        <div className={classes.disclaimer}>
            <p>This copyrighted communication is the sole property of Priority Power Management LLC (“PPM”). PPM has provided this communication for informational purposes only and it is transmitted on an “as is” basis. None of PPM nor its affiliates make any representation or warranty, express or implied, with respect to this communication, and PPM and its affiliates shall have no liability whatsoever for any defect, deficiency, error, or omission in any statement contained in or in any way related to this communication, including without limitation the implied warranty of merchantability or the implied warranty of fitness for a particular purpose. Any forward-looking information contained herein, including without limitation forecasts, estimates, projections, opinions, or conclusions, is dependent upon numerous technical, economic, and other conditions over which PPM has no control, and which may or may not occur. You are hereby cautioned not to rely on such forward-looking information, and reliance thereupon by any person or entity is at your sole risk. This communication or any description, reproduction or account thereof may not be attributed to PPM or its affiliates, or distributed, quoted, or otherwise transmitted without the express, prior written consent of PPM. All rights reserved.</p>
            <div className={`${classes.disclaimerCheckboxContainer}` } onClick={() => handleCheckboxChange()}>
                <label className={`${classes.disclaimerCheckboxContainerText}` } for="exampleCheckbox">Acknowledge</label>
                <input className={`${classes.disclaimerCheckbox}` } type="checkbox" id="subCheckbox" name="subCheckbox"></input>
            </div>
        </div>

      )}
    </div>
  );
}

export default LoginForm;
